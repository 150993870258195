@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap");

@import "react-sliding-pane/dist/react-sliding-pane.css";

* {
  overscroll-behavior: none;
}

.slide-pane__content {
  @apply px-4 pt-0 pb-10 flex flex-col;
}
